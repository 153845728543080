import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function PodcastStory({data}) {
    return (
        <>
            <div className="tekrevol_dark_bg p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} lg={7} xl={7} xxl={7}>
                            <div className="example" data-text={data?.story_body_txt}>
                                <h2>{HtmlParser(checkData(data, 'story_title'))}</h2>
                            </div>
                            <div>{HtmlParser(checkData(data, 'story_text'))}</div>
                        </Col>
                        <Col xs={12} md={6} lg={5} xl={5} xxl={5}>
                            <div>{HtmlParser(checkData(data, 'flod_video'))}</div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
}

export default PodcastStory;